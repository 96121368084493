import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { forkJoin, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { PermissionsWebGuard } from '../../guards/permissions/permissions-web-guard.guard';
import { uniq } from 'lodash';
import { HttpClient } from '@angular/common/http';
import { Emission } from '@iconic-air/models';
import { RawEmissionService } from '../raw-emission/raw-emission.service';
import { FacilitiesDatabaseService } from '../../services-database/facilities-service/facilities-database.service';
import { SaveService } from '../save/save.service';
import { SnackbarsDatabaseService } from '../../services-database/snackbars/snackbars-database.service';

@Injectable({
  providedIn: 'root',
})
export class DataUploadService {
  public providers;
  constructor(
    private _afs: AngularFirestore,
    private _perms: PermissionsWebGuard,
    private _http: HttpClient,
    private _saveService: SaveService,
    private _rawEmissionService: RawEmissionService,
    private _facilitiesDatabaseService: FacilitiesDatabaseService,
    private _snackbarDatabase: SnackbarsDatabaseService,
  ) {}

  async validateFile(file: File, type: string, year?: number) {
    const id = await this._snackbarDatabase.start({
      message: 'Reading file',
      createdDate: Number(new Date()),
      showProgress: true,
      showDuration: true,
      modalName: 'UploadDialogComponent',
      progress: 0,
      icon: 'upload',
      closed: false,
    });
    const extension = file.name.split('.').pop();
    const filePath = `customers/${
      this._perms.activeCustomerId
    }/${type}/${file.name?.split('.').slice(0, -1).join('.')}_${Number(
      new Date(),
    )}${extension ? '.' + extension : ''}`;
    const fileUrl = await this._saveService.uploadFile(
      file,
      type,
      `${file.name?.split('.').slice(0, -1).join('.')}_${Number(new Date())}${
        extension ? '.' + extension : ''
      }`,
      true,
    );
    const results = await lastValueFrom(
      this._http.post('/validateFile', {
        fileName: file.name,
        type,
        year,
        filePath,
        fileType: type,
        fileUrl,
        id,
      }),
    );
    return results;
  }

  async saveFile(id: string, type: string) {
    return (await lastValueFrom(
      this._http.post('/saveFile', { id, type }),
    )) as {
      inserted: number;
      updated: number;
    };
  }

  getProviders() {
    return this._afs
      .collection('customers')
      .doc(this._perms.activeCustomerId)
      .collection('providers')
      .valueChanges();
  }

  getAllEmissionYears() {
    return forkJoin([
      this._rawEmissionService.getRawEmissionYears(),
      this.getEmissionYears(),
    ]).pipe(
      map((data) => {
        return uniq(data[0]?.map((year) => Number(year)).concat(data[1])).sort(
          (a, b) => a - b,
        );
      }),
    );
  }

  // goes through all emissions docs and then creates an array of unique years that exists in all emissions docs
  getEmissionYears() {
    return this._afs
      .collection('customers')
      .doc(this._perms.activeCustomerId)
      .collection('emissions')
      .get()
      .pipe(
        map((emissions) => {
          const years: number[] = [];
          emissions.forEach((emission: any) => {
            if (
              emission?.data()?.year &&
              !years.includes(Number(emission?.data()?.year))
            )
              years.push(Number(emission?.data()?.year));
          });
          years.sort((a, b) => {
            return a - b;
          });
          return years;
        }),
      );
  }

  // gets all emissions docs for customer
  getEmissions() {
    return this._afs
      .collection('customers')
      .doc(this._perms.activeCustomerId)
      .collection<Emission>('emissions')
      .valueChanges();
  }

  getGasSamplesAndSurveys() {
    const facQuery = this._facilitiesDatabaseService.getFacilities();

    const surveysQuery = this._afs
      .collection('customers')
      .doc(this._perms.activeCustomerId)
      .collection('surveys')
      .get();

    return forkJoin([facQuery, surveysQuery]);
  }
}
