import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, forkJoin } from 'rxjs';
import { saveAs } from 'file-saver';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as moment from 'moment';
import * as ExcelJS from 'exceljs';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { PermissionsWebGuard } from '../../guards/permissions/permissions-web-guard.guard';
import { take, lastValueFrom } from 'rxjs';
import { Facility, Leak, Survey, LeakAsset } from '@iconic-air/models';
import { titleCase } from 'title-case';
import { PdfBuilderService } from './pdf-builder.services';
import { CameraPipe } from '../../pipes/camera.pipe';
import { ProgramTypePipe } from '../../pipes/program-type.pipe';
import { TitleCasePipe } from '@angular/common';
import { FacilitiesDatabaseService } from '../../services-database/facilities-service/facilities-database.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CreateLdarReportService {
  get customers() {
    return this.perms.customers;
  }
  users: any[] = [];
  survey: Observable<Survey>;
  filteredLeakAssets: LeakAsset[];
  reportLabels = {
    information: 'Survey Information',
    leakTitle: 'Leak Details',
    repairTitle: 'Repair History',
    normal: 'Normal',
    visual: 'Visual',
    summary: 'Summary Snapshot',
    facilityName: 'LOCATION NAME',
    plantAddress: 'ADDRESS OF PLANT',
    city: 'CITY',
    zipCode: 'ZIP CODE',
    inspectionStart: 'INSPECTION START DATE',
    inspectionEnd: 'INSPECTION END DATE',
    leaksDetected: 'LEAKS DETECTED',
    notes: 'NOTES',
    gpsLocation: 'GPS LOCATION',
    dueDate: 'DUE DATE',
    deviceUsed: 'DEVICE USED',
    componentType: 'COMPONENT TYPE',
    componentSize: 'COMPONENT SIZE',
    ldarTag: 'LDAR TAG',
    description: 'DESCRIPTION',
    leakId: 'LEAK ID',
    repairDescription: 'LAST REPAIR ATTEMPT',
    repairStatus: 'REPAIR STATUS',
    facilityAuth: 'FACILITY AUTHORIZATION',
    operatorExp: 'OPERATOR EXPERIENCE',
    deviations: 'DEVIATIONS FROM MONITORING PLAN',
    ambientTemp: 'AMBIENT TEMPERATURE',
    skyConditions: 'SKY CONDITIONS',
    maxWindSpeed: 'MAX WIND SPEED',
    monitoringDevice: 'MONITORING DEVICE',
    calibGasType: 'CALIBRATION GAS TYPE',
    flowRate: 'FLOW RATE',
    distance: 'DISTANCE',
    operatorName: 'OPERATOR NAME',
    leakQuantification: 'LEAK QUANTIFICATION',
    leakDetectionMethod: 'LEAK DETECTION METHOD',
    leakDetectionDate: 'LEAK DETECTION DATE',
    leakStatus: 'STATUS',
    dateOfRepair: 'DATE OF REPAIR',
    repairMethod: 'REPAIR METHOD',
    resurveyDate: 'RESURVEY DATE',
    resurveyMethod: 'RESURVEY METHOD',
  };

  constructor(
    private afs: AngularFirestore,
    private _http: HttpClient,
    private perms: PermissionsWebGuard,
    private pdfBuilderService: PdfBuilderService,
    private cameraPipe: CameraPipe,
    private programTypePipe: ProgramTypePipe,
    private titleCasePipe: TitleCasePipe,
    private _facilityDatabaseService: FacilitiesDatabaseService,
  ) {
    this.afs
      .collection('users')
      .get()
      .subscribe((users) => {
        if (users.docs) {
          this.users = users.docs;
        }
      });
  }

  generateLeakReport(
    fileType: string,
    selectedFacilities: string[],
    fromDate?: number,
    toDate?: number,
    selectedReportType?: string,
    surveyId?: string,
    startDateFormatted?: string,
    endDateFormatted?: string
  ) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Apply the offset to the UTC timestamp
    return this._http.post(
      '/api/reports/leaks/' + fileType,
      {
        fileType,
        selectedFacilities,
        fromDate,
        toDate,
        selectedReportType,
        surveyId,
        startDateFormatted,
        endDateFormatted,
        timeZone
      },
      {
        responseType: 'blob'
      },
    );
  }

  getData(surveyId, facilityId): Observable<any> {
    const surveyResponse = this.afs
      .collection<Survey>('customers')
      .doc(this.customers[0].id)
      .collection('surveys')
      .doc(surveyId)
      .valueChanges()
      .pipe(take(1));
    const facilityResponse = this._facilityDatabaseService
      .getFacilityObservable(facilityId)
      .pipe(take(1));
    return forkJoin([surveyResponse, facilityResponse]);
  }
}
