import { Injectable } from '@angular/core';
import { PermissionsWebGuard } from '../../guards/permissions/permissions-web-guard.guard';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UserSnackbar, UserSnackbarCreate } from '@iconic-air/models';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SnackbarsDatabaseService {
  constructor(
    private _afs: AngularFirestore,
    private _perms: PermissionsWebGuard,
  ) {}

  async start(snackbar: UserSnackbarCreate) {
    const id = this._afs.createId();
    await this._afs
      .collection('users')
      .doc(this._perms.userData?.uid)
      .collection('snackbars')
      .doc(id)
      .set(snackbar);
    return id;
  }

  async currentSnackbars() {
    const docs = await lastValueFrom(
      this._afs
        .collection('users')
        .doc(this._perms.userData?.uid)
        .collection('snackbars', (ref) => ref.where('closed', '==', false))
        .get(),
    );
    const snackbnars: UserSnackbar[] = [];
    docs.forEach((doc) => {
      const data = doc.data();
      const id = doc.id;
      if (data?.closed) return;
      snackbnars.push({
        ...data,
        id,
      } as UserSnackbar);
    });
    return snackbnars;
  }

  subscribeSnackbars() {
    return this._afs
      .collection('users')
      .doc(this._perms.userData?.uid)
      .collection('snackbars')
      .snapshotChanges();
  }

  async close(id: string) {
    await this._afs
      .collection('users')
      .doc(this._perms.userData?.uid)
      .collection('snackbars')
      .doc(id)
      .update({ closed: true });
  }
}
