<mat-form-field
  class="{{classes}}"
  matTooltip="{{ multiple ? (selectedBusinessArea?.length ? selectedBusinessArea?.length + ' Selected' : '') : ''  | titlecase}}"
  matTooltipPosition="below"
  [appearance]="appearance"
>
  <mat-label>Business area</mat-label>
  <mat-select
    [disabled]="disabled"
    #matSelect
    [(ngModel)]="selectedBusinessArea"
    [multiple]="multiple"
    placeholder="Business area"
  >
    <mat-select-trigger *ngIf="multiple">
      <ng-container *ngIf="loading">
        <mat-progress-spinner
          *ngIf="loading"
          [diameter]="18"
          [mode]="'indeterminate'"
        >
        </mat-progress-spinner>
      </ng-container>
      <ng-container *ngIf="!loading">
        <span class="text-primary">Area: </span>
        {{selectedBusinessArea?.length >= businessAreas?.length ? 'All' :
        selectedBusinessArea?.length + ' Selected'}}
      </ng-container>
    </mat-select-trigger>
    <div novalidate class="mat-filter">
      <input
        matInput
        [(ngModel)]="businessAreaFilter"
        [ngModelOptions]="{standalone: true}"
        filterBasinszclass="mat-filter-input"
        placeholder="Search..."
      />
    </div>
    <ng-container *ngIf="disableScroll">
      <div class="example-margin" ripple *ngIf="multiple">
        <mat-checkbox
          [checked]="selectedBusinessArea?.length >= businessAreas?.length"
          (change)="selectAllBusinessAreas($event)"
          *ngIf="!businessAreaFilter || ['all'].includes(businessAreaFilter.toLowerCase())"
        >
          {{'All' | titlecase }}</mat-checkbox
        >
        <h1
          *ngIf="businessAreaFilter && !['all'].includes(businessAreaFilter.toLowerCase())"
        >
          {{'Search Results...' | titlecase}}
        </h1>
      </div>
      <ng-container *ngFor="let area of businessAreas; trackBy: trackByFn">
        <mat-option
          [value]="area"
          *ngIf="!businessAreaFilter || area?.toLowerCase()?.includes(businessAreaFilter?.toLowerCase())"
        >
          <span [innerHtml]="area | highlight:businessAreaFilter"></span>
        </mat-option>
      </ng-container>
    </ng-container>
    <cdk-virtual-scroll-viewport
      *ngIf="!disableScroll"
      minBufferPx="200"
      maxBufferPx="400"
      itemSize="50"
      [style.height.px]="5 * 48"
      class="relative"
    >
      <div class="example-margin" ripple *ngIf="multiple">
        <mat-checkbox
          [checked]="selectedBusinessArea?.length >= businessAreas?.length"
          (change)="selectAllBusinessAreas($event)"
          *ngIf="!businessAreaFilter || ['all'].indexOf(businessAreaFilter.toLowerCase()) > -1"
        >
          {{'All' | titlecase }}</mat-checkbox
        >
        <h1
          *ngIf="businessAreaFilter && ['all'].indexOf(businessAreaFilter.toLowerCase()) === -1"
        >
          {{'Search Results...' | titlecase}}
        </h1>
      </div>
      <mat-option
        *cdkVirtualFor="let area of businessAreas | sort:'asc'"
        [value]="area"
        [ngClass]="{'hidden': (businessAreaFilter && area?.toLowerCase().indexOf(businessAreaFilter.toLowerCase()) === -1)}"
      >
        <span
          [innerHtml]="area | titlecase | highlight:businessAreaFilter"
        ></span>
      </mat-option>
    </cdk-virtual-scroll-viewport>
  </mat-select>
</mat-form-field>
